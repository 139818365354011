import { Retailers } from 'assets';
import { Hero } from 'components/markdown/hero';
import { Text } from 'components/typography/text';
import { Link, LinkIcon } from 'components/ui/link';
import type { PageProps } from 'gatsby';
import { useTheme } from 'next-themes';
import { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PageLayout } from 'src/layouts/page-layout';
import { NColumn } from '../components/markdown/n-column';
import { type Workflow, useContentImage, useWorkflowsData } from '../hooks';

export type WorkflowCardProps = {
  title: string;
  description: string;
  image: string;
  links: ReactNode;
};

const WorkflowCard = ({ title, description, image, links }: WorkflowCardProps) => {
  const { theme } = useTheme();
  const contentImages = useContentImage(`cards/${image}`, 'pair', 'workflow');
  const contentImage = useMemo(() => (theme === 'light' ? contentImages[0] : contentImages[1]), [contentImages, theme]);

  return (
    <div className="flex h-full flex-col overflow-hidden rounded-lg bg-neutral-100 dark:bg-dark-800">
      {!contentImage || image.includes('retailers-card') ? (
        <Retailers data-testid="retailers-svg" dark={theme === 'dark'} />
      ) : (
        <img alt={contentImage.publicURL} src={contentImage.publicURL} />
      )}
      <div className="flex-1 items-baseline px-6 pt-6">
        <h3 className="mb-4">{title}</h3>
        <Text variant="secondary">{description}</Text>
      </div>
      <div className="flex flex-col">{links}</div>
    </div>
  );
};

export const JourneyExplorer = ({ workflows }: { workflows: Workflow[] }) => {
  const { formatMessage } = useIntl();

  return (
    <div data-testid="workflows-page">
      <Hero headline={formatMessage({ id: 'workflows.title' })} copy={formatMessage({ id: 'workflows.tagline' })} />
      <NColumn>
        {workflows?.map((workflow) => (
          <WorkflowCard
            key={workflow.heading + workflow.link}
            description={workflow.description}
            title={workflow.heading}
            image={workflow.image}
            links={
              <div className="flex flex-col gap-4 px-6 py-6">
                {workflow.workflows.map(({ summary, workflowId }) => (
                  <div key={workflow.link + workflowId}>
                    <Link icon={LinkIcon.Trailing} href={`${workflow.link}/${workflowId}`} size="16" ariaLabel="label">
                      <span aria-hidden>{summary}</span>
                    </Link>
                  </div>
                ))}
              </div>
            }
          />
        ))}
      </NColumn>
    </div>
  );
};

export const JourneyExplorerPage = ({ location }: PageProps) => {
  const workflows = useWorkflowsData();
  const commitDate = workflows.length > 0 ? Math.max(...workflows.map((workflow) => workflow.commitDate)) : undefined;

  return (
    <PageLayout location={location} isLargeContent title="Journey Explorer" commitDate={commitDate}>
      <JourneyExplorer workflows={workflows} />
    </PageLayout>
  );
};

export default JourneyExplorerPage;
